import classNames from 'classnames';
import { StartNowButton } from '../Components';
import { useEffect, useState } from 'react';

const items = [
  {
    index: 0,
    headerClassName: '!text-mf-blue-base',
    circleClassName: '!bg-mf-blue-50',
    label: 'Boost organization',
    imgUrl: '/images/home/section-2-img-1.png',
    alt: 'Illustration representing organisation and productivity',
  },
  {
    index: 1,
    headerClassName: '!text-mf-lilac-base',
    circleClassName: '!bg-mf-lilac-50',
    label: 'Capture essentials',
    imgUrl: '/images/home/section-2-img-2.png',
    alt: 'Graphic showing note-taking or capturing important ideas',
  },
  {
    index: 2,
    headerClassName: '!text-mf-green-base text-5xl lg:!text-[88px]',
    circleClassName: '!bg-mf-green-50',
    label: 'Simplify everything',
    imgUrl: '/images/home/section-2-img-3.png',
    alt: 'Visual metaphor for simplifying complex information',
  },
  {
    index: 3,
    headerClassName: '!text-mf-yellow-base',
    circleClassName: '!bg-mf-yellow-50',
    label: 'Share easily',
    imgUrl: '/images/home/section-2-img-4.png',
    alt: 'Illustration of easy sharing or collaboration tools',
  },
];

export const Section2 = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % items.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.animatedSection.root}>
        <div className="h-auto">
          {items.map((item, index) => (
            <div
              key={item.index}
              className={styles.animatedSection.headerText(
                activeIndex === index,
                item.headerClassName
              )}
            >
              {item.label}
              <span className={styles.spanText}>with Mindfeed</span>
            </div>
          ))}
        </div>
        {items.map((item, index) => (
          <div
            className={`${styles.animatedSection.circle.root(
              activeIndex === index
            )} ${item.circleClassName}`}
            key={item.index}
          >
            <div className={styles.animatedSection.circle.wrapper}>
              <img
                src={item.imgUrl}
                alt={item.alt}
                className={styles.animatedSection.circle.image(
                  activeIndex === index
                )}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.textSection.root}>
        <h2 className={styles.textSection.title}>with MindFeed</h2>
        <p className={styles.textSection.description}>
          Bring everything together in one place with MindFeed and keep your
          ideas organized effortlessly.
        </p>
        <div className={styles.textSection.buttonWrapper}>
          <StartNowButton />
        </div>
      </div>
    </div>
  );
};

const styles = {
  spanText: 'block mt-2 text-mf-off-black-950 font-bold text-5xl lg:hidden',
  container: 'max-w-7xl px-5 mx-auto pb-9 lg:pb-36',
  animatedSection: {
    root: 'flex flex-col items-center justify-center lg:min-h-[353px] lg:relative lg:mt-28',
    headerText: (isActive: boolean, className?: string) => {
      return classNames(
        className || '',
        'flex flex-col justify-end font-bold text-center text-5xl z-10 transition-all duration-1000 ease-in-out delay-300',
        'lg:block lg:text-[90px] lg:text-left lg:absolute lg:right-[380px] lg:top-[176.5px]',
        isActive
          ? 'opacity-100 translate-y-0 h-[152px] lg:h-auto'
          : 'opacity-0 translate-y-6 h-0'
      );
    },
    circle: {
      root: (isActive: boolean) => {
        return classNames(
          'rounded-full w-[353px] right-0 top-0 flex items-center justify-center',
          'lg:w-[449px] lg:absolute',
          'transition-opacity transition-translate-y duration-1000 ease-in-out',
          isActive
            ? 'opacity-100 translate-y-0 h-[353px] lg:h-[449px] delay-300'
            : 'opacity-0 translate-y-6 h-0'
        );
      },
      wrapper: 'flex items-center justify-center lg:relative w-full h-full',
      image: (isActive: boolean) => {
        return classNames(
          'absolute max-w-[262px] z-10 transition-all duration-1000 ease-in-out',
          isActive
            ? 'opacity-100 translate-y-0 h-auto delay-300'
            : 'opacity-0 translate-y-6 h-0'
        );
      },
    },
  },
  textSection: {
    root: 'flex flex-col items-center justify-center',
    title: 'hidden lg:block text-mf-off-black-950 font-bold text-[90px] z-10',
    description:
      'text-center mt-10 text-xl text-mf-off-black-800 leading-8 z-10 lg:text-left',
    buttonWrapper: 'mt-12',
  },
};
