import { marketing } from 'config/global';
import Link from 'next/link';

const List = ({ className }: React.HTMLAttributes<HTMLUListElement>) => (
  <ul className={`${styles.list.root} ${className}`}>
    <li className={`${styles.list.item} mb-5`}>
      Take notes while you’re away from your laptop.
    </li>
    <li className={`${styles.list.item} mb-5`}>
      The MindFeed app is optimized for mobile so you can capture, write, and
      organize your ideas or notes on the go.
    </li>
    <li className={styles.list.item}>
      And with instant sync across devices, your thoughts are always where you
      need them.
    </li>
  </ul>
);

export const Section7 = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <div className={styles.content.base}>
        <div className={styles.content.imageContainer}>
          <img
            className={styles.content.mainImage}
            src="/images/home/section-7-img.png"
            alt="MindFeed mobile app"
          />
          <img
            className={styles.content.shadowImage}
            src="/images/home/section-7-img-shadow.png"
            alt=""
            aria-hidden="true"
          />
        </div>
        <List className="block lg:hidden" />
      </div>
      <div className={styles.textSection.base}>
        <h2 className={styles.textSection.heading}>
          Download the{' '}
          <span className={styles.textSection.highlight}>mobile app</span>
        </h2>
        <p className={styles.textSection.paragraph}>
          Seamlessly transition from laptop to phone.
        </p>
        <List className="hidden lg:block" />
        <div className={styles.store.container}>
          <Link href={marketing.appStoreUri} target="_blank">
            <img
              src="/images/app-store-logo-dark.png"
              className={styles.store.image}
              alt="App Store Logo"
            />
          </Link>
          <Link href={marketing.googlePlayUri} target="_blank">
            <img
              src="/images/gplay-logo-dark.png"
              className={styles.store.googlePlay}
              alt="Goggle Play Logo"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
);

const styles = {
  container: 'flex items-center justify-center px-5',
  wrapper:
    'flex flex-col-reverse items-center pt-10 pb-0 lg:py-24 lg:py-40 max-w-7xl mx-auto lg:flex-row',
  content: {
    base: 'flex flex-col items-center lg:items-start lg:w-[495px] overflow-visible',
    imageContainer:
      'flex w-full h-[616px] mb-16 items-center justify-center lg:justify-start lg:mb-0 relative overflow-visible',
    mainImage: 'absolute w-[304px] h-full overflow-visible z-10',
    shadowImage: 'absolute top-0 left-0 w-full h-[760px] -z-10 hidden lg:block',
  },
  list: {
    root: 'mb-16 text-mf-off-black-800 px-5',
    item: 'list-disc list-outside text-lg leading-8 text-mf-off-black-500',
  },
  textSection: {
    base: 'max-w-[566px] mb-12 lg:mb-0',
    heading:
      'text-4xl lg:text-5xl font-bold text-mf-off-black-950 mb-5 lg:mb-8',
    highlight: 'text-mf-blue-base',
    paragraph: 'text-xl text-mf-off-black-950 leading-8 mb-8',
  },
  store: {
    container: 'flex items-center justify-center lg:justify-start',
    image: 'w-[132px] h-[44px] mr-5',
    googlePlay: 'w-[148px] h-[44px]',
  },
};
