import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfinity,
  faLaptopMobile,
  faLightbulbOn,
  faSearch,
  faUserGroup,
} from '@fortawesome/pro-solid-svg-icons';

const items = [
  {
    icon: <FontAwesomeIcon icon={faLightbulbOn} size="xl" />,
    title: 'Easily organize your ideas',
    subtitle:
      'MindFeed lets you add notes and organize them into themed collections and boxes.',
  },
  {
    icon: <FontAwesomeIcon icon={faLaptopMobile} size="xl" />,
    title: 'Access your notes anywhere',
    subtitle: 'Your notes sync across devices, so your ideas are always safe.',
  },
  {
    icon: <FontAwesomeIcon icon={faUserGroup} size="xl" />,
    title: 'Share your ideas with others',
    subtitle:
      'Share your notes effortlessly with MindFeed, whether for projects or inspiration.',
  },
  {
    icon: <FontAwesomeIcon icon={faInfinity} size="xl" />,
    title: 'Everything in one place',
    subtitle:
      'Stop losing this precious information in your old and boring notes apps!',
  },
  {
    icon: <FontAwesomeIcon icon={faSearch} size="xl" />,
    title: 'Quick search to find your thoughts',
    subtitle:
      'With powerful search, find all your ideas in seconds, even across multiple boxes and collections.',
  },
];

const firstRow = items.slice(0, 3);
const secondRow = items.slice(3, 5);

type ListItemProps = { icon: React.ReactNode; title: string; subtitle: string };

const ListItem = ({ icon, title, subtitle }: ListItemProps) => (
  <div className={styles.listItem.base}>
    <div className={styles.listItem.circle}>
      <div className={styles.listItem.icon}>{icon}</div>
    </div>
    <div>
      <h4 className={styles.listItem.title}>{title}</h4>
      <p className={styles.listItem.subtitle}>{subtitle}</p>
    </div>
  </div>
);

export const Section6 = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <h1 className={styles.heading.base}>
        Easy-to-use tools to keep you{' '}
        <span className={styles.heading.highlight}>organized</span>
      </h1>
      <div className={styles.row}>
        {firstRow.map((item, i) => (
          <ListItem {...item} key={i} />
        ))}
      </div>
      <div className={`${styles.row} mb-0`}>
        {secondRow.map((item, i) => (
          <ListItem {...item} key={i} />
        ))}
      </div>
    </div>
  </div>
);

const styles = {
  container: 'bg-mf-gray-50',
  wrapper: 'py-10 lg:pt-16 lg:pb-20 max-w-7xl mx-auto px-5',
  heading: {
    base: 'text-4xl font-bold text-left text-mf-off-black-950 mb-12 lg:mb-28 lg:text-5xl lg:text-center',
    highlight: 'text-mf-blue-base',
  },
  row: 'flex flex-col justify-center mb-10 gap-10 lg:md-0 lg:flex-row lg:gap-20',
  listItem: {
    base: 'text-left flex flex-row items-center lg:text-center lg:flex-col lg:max-w-[370px]',
    circle:
      'flex items-center justify-center rounded-full min-w-14 min-h-14 lg:min-w-20 lg:min-h-20 bg-mf-blue-50 text-mf-blue-base mb-8 mr-4 lg:mr-0',
    icon: 'flex items-center justify-center w-10 h-10',
    title: 'text-xl text-mf-off-black-950 font-semibold mb-3',
    subtitle: 'text-mf-off-black-800 text-lg',
  },
};
