import classNames from 'classnames';
import { StartNowButton } from '../Components';

export const Section1 = () => (
  <div className={styles.container}>
    <div className={styles.left.container}>
      <h1 className={styles.left.title}>
        Simplify your <span className={styles.highlight}>note-taking</span> and{' '}
        <span className={styles.highlight}>idea organization</span>
      </h1>
      <p className={styles.left.p1}>
        Track in one place all your ideas and inspirations with MindFeed! Easily
        create, organize, and share unique content like quotes, texts, links,
        images, and more....
      </p>
      <p className={styles.left.p2}>Start creating today with MindFeed!</p>
      <div className={styles.left.cta.wrapper}>
        <StartNowButton className={styles.left.cta.button} />
      </div>
    </div>
    <div className={styles.right.container}>
      <div
        className={styles.right.img}
        style={{
          backgroundImage: `url(/images/home/section-1-img.png)`,
        }}
      />
    </div>
  </div>
);

const styles = {
  container: classNames(
    'px-0 pt-8 pb-14 flex flex-col items-center lg:px-5 lg:pb-0 lg:flex-row lg:pt-14 max-w-7xl',
    'mx-auto overflow-visible sm:overflow-hidden lg:overflow-visible'
  ),
  left: {
    container: 'px-5 lg:min-w-[629px] mb-8 lg:px-0 lg:mb-0 lg:mr-20',
    title:
      'text-4xl font-bold text-mf-off-black-950 sm:leading-[57.28px] sm:text-5xl',
    p1: 'mt-8 text-xl text-mf-off-black-500 leading-8',
    p2: 'mt-8 text-xl font-semibold text-mf-off-black-800 leading-8',
    cta: {
      wrapper: 'mt-10',
      button: '!inline-block !w-auto',
    },
  },
  right: {
    container: 'w-full overflow-visible sm:overflow-hidden lg:overflow-visible',
    img: 'bg-cover bg-left bg-no-repeat h-[354px] w-[497px] lg:w-[872px] lg:h-[620px]',
  },
  highlight: 'text-mf-blue-base',
};
