import { appRoutes } from 'config';
import { useAuthentication } from 'hooks/useAuthentication';
import Router from 'next/router';
import { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
} from './Sections';

const Home = (): JSX.Element => {
  const { authenticatedUser } = useAuthentication();

  useEffect(() => {
    if (authenticatedUser) {
      Router.push(appRoutes.MEMORY_BOX);
    }
  }, [authenticatedUser]);

  return (
    <ParallaxProvider>
      <div className="p-0 flex font-inter">
        <div className="w-full mx-auto my-12">
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section7 />
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default Home;
