import LinkBtn, { LinkBtnProps } from 'components/elements/LinkBtn';
import { appRoutes } from 'config';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';

export const StartNowButton = ({
  className,
}: Pick<LinkBtnProps, 'className'>) => (
  <LinkBtn
    className={`${className} !rounded-full !font-medium !text-xl !leading-8 bg-mf-blue-base`}
    href={appRoutes.LOGIN}
    text="Start now"
    onClick={() => reportUserClickedOnEvent('Get Started', 'Homepage Hero')}
  />
);
