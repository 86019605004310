import classNames from 'classnames';

export const Section5 = () => (
  <div className={styles.container}>
    <h1 className={styles.title.root}>
      Write it.
      <br className={styles.title.break} /> Save it.
      <br className={styles.title.break} /> Remember it.
    </h1>
    <div className={styles.heading.container}>
      <h3 className={styles.heading.text}>
        <span className={styles.heading.highlight}>Never lose</span> your ideas
        again with MindFeed
      </h3>
    </div>
    <div className={styles.text.container}>
      <h3 className={styles.text.content}>
        Whether you have a brilliant idea in the middle of the night or a
        project to manage, MindFeed helps you capture every thought as soon as
        it comes to mind. No need to remember everything, as your note
        organization app keeps everything within reach.
      </h3>
    </div>
  </div>
);

const styles = {
  container:
    'flex flex-col px-5 justify-center mt-14 mb-20 lg:mt-24 lg:mb-36 mx-auto max-w-7xl',
  title: {
    root: classNames(
      'text-[55px] font-bold leading-[75px] mb-24 text-mf-gray-300 text-center tracking-wide',
      'lg:font-bold lg:mb-40 lg:leading-[127px] lg:text-[81px]'
    ),
    break: 'block lg:hidden',
  },
  heading: {
    container: 'flex mb-8 lg:mb-12',
    text: 'text-4xl font-bold text-mf-off-black-950 lg:text-5xl max-w-[676px]',
    highlight: 'text-mf-blue-base',
  },
  text: {
    container: 'flex justify-end',
    content: 'text-xl text-mf-off-black-800 max-w-[709px]',
  },
};
