import { useEffect, useState } from 'react';
import Link from 'next/link';
import { appRoutes } from 'config/appRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faImage,
  faLinkHorizontal,
  faListCheck,
  faCommentQuote,
  faText,
} from '@fortawesome/pro-solid-svg-icons';

const MemoItems = [
  {
    buttonText: 'Text',
    icon: <FontAwesomeIcon icon={faText} />,
    imgUrl: '/images/home/section-4-img-1.png',
    title: 'Bring your ideas to life',
    subtitle:
      'Easily capture and structure your thoughts with a clear text editor designed for maximum productivity.',
  },
  {
    buttonText: 'Quote',
    icon: <FontAwesomeIcon icon={faCommentQuote} />,
    imgUrl: '/images/home/section-4-img-2.png',
    title: 'Highlight your insights with style',
    subtitle:
      'Showcase inspiring quotes or key moments with elegant and captivating designs.',
  },
  {
    buttonText: 'Link',
    icon: <FontAwesomeIcon icon={faLinkHorizontal} />,
    imgUrl: '/images/home/section-4-img-3.png',
    title: 'Connect your ideas with interactive links',
    subtitle:
      'Add clickable links to connect your ideas, articles, or references all in one place.',
  },
  {
    buttonText: 'Image',
    icon: <FontAwesomeIcon icon={faImage} />,
    imgUrl: '/images/home/section-4-img-4.png',
    title: 'Use images to make your ideas shine',
    subtitle:
      'Upload images to better visualize and organize in a clear and inspiring way, and never lose them.',
  },
  {
    buttonText: 'List',
    icon: <FontAwesomeIcon icon={faListCheck} />,
    imgUrl: '/images/home/section-4-img-5.png',
    title: 'Organize your ideas with intuitive lists',
    subtitle:
      'Create checkbox lists to structure your tasks, projects, or concepts clearly and efficiently.',
  },
];

export const Section4 = () => {
  const [activeItem, setActiveItem] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItem((prev) => (prev + 1) % MemoItems.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title.base}>
        List your thoughts, create your memos, and keep them{' '}
        <span className={styles.title.highlightedText}>forever</span>
      </h2>
      <div className={styles.contentWrapper}>
        <div
          className={`${styles.contentCol.base} ${styles.contentCol.buttons}`}
        >
          {MemoItems.map((item, index) => (
            <button
              key={index}
              onClick={() => setActiveItem(index)}
              className={`${styles.contentCol.button.base} ${
                activeItem === index
                  ? styles.contentCol.button.active
                  : 'bg-transparent'
              } ${
                (activeItem + 1 >= MemoItems.length ? 0 : activeItem + 1) ===
                index
                  ? styles.contentCol.button.animatedBorder
                  : ''
              }`}
            >
              <div className="mr-3 w-6 h-6">{item.icon}</div>
              <p className="text-xl">{item.buttonText}</p>
            </button>
          ))}
        </div>
        <div
          className={`${styles.contentCol.base} ${styles.contentCol.content}`}
        >
          <div className={styles.contentCol.imageWrapper}>
            {MemoItems.map((item, index) => (
              <div
                key={index}
                className={`${styles.contentCol.imageTransition} ${
                  activeItem === index
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-6'
                }`}
                style={{
                  transitionDelay: activeItem === index ? '300ms' : '0ms',
                }}
              >
                <img
                  className={styles.contentCol.image}
                  src={item.imgUrl}
                  alt="Mindfeed"
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={`${styles.contentCol.base} ${styles.contentCol.content}`}
        >
          <div className={styles.contentCol.textWrapper}>
            {MemoItems.map((item, index) => (
              <div
                key={index}
                className={`${styles.contentCol.textTransition} ${
                  activeItem === index
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-6'
                }`}
                style={{
                  transitionDelay: activeItem === index ? '300ms' : '0ms',
                }}
              >
                <h3 className={styles.contentCol.heading}>{item.title}</h3>
                <p className={styles.contentCol.paragraph}>{item.subtitle}</p>
                <Link
                  href={appRoutes.LOGIN}
                  className={styles.contentCol.linkWrapper}
                >
                  <button className={styles.contentCol.linkButton}>
                    <div className="w-6 h-6">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </button>
                  <h4 className={styles.contentCol.linkText}>
                    Explore Mindfeed
                  </h4>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container:
    'flex flex-col px-5 items-center justify-center mx-auto mt-28 max-w-7xl lg:mt-36',
  title: {
    base: 'text-4xl font-bold text-center text-mf-off-black-950 sm:leading-[57.28px] sm:text-5xl lg:max-w-[930px]',
    highlightedText: 'text-mf-blue-base',
  },
  contentWrapper:
    'flex flex-col items-center w-full justify-between overflow-hidden mt-12 lg:grid lg:grid-cols-3 lg:mt-28',
  contentCol: {
    base: 'flex w-full lg:w-auto lg:min-w-full',
    content: 'flex-col items-center justify-center',
    buttons:
      'flex-row justify-start items-start overflow-auto lg:flex-col lg:justify-center lg:items-center',
    button: {
      base: 'flex justify-center items-center py-4 px-[30px] transition-all duration-1000 ease-in-out rounded-full border hover:bg-mf-light-gray-100 text-mf-off-black-800 border-mf-gray-300 lg:w-[150px] mr-3 lg:mr-0 lg:mb-[35px]',
      active: 'bg-mf-off-black-950 text-white hover:bg-mf-off-black-900',
      animatedBorder: 'animated-border-button',
    },
    imageWrapper:
      'flex items-center justify-center p-5 mr-0 mb-5 w-full relative lg:mb-0 min-h-[550px] min-w-[420px] lg:w-auto lg:min-h-[620px]',
    imageTransition:
      'top-0 left-0 right-0 bottom-0 z-10 absolute transition-all duration-1000 ease-in-out',
    image:
      'h-full min-h-[550px] lg:min-h-[580px] min-w-full object-contain lg:object-contain',
    textWrapper:
      'relative min-w-[330px] min-h-[304px] w-full lg:w-auto flex flex-col',
    textTransition:
      'absolute top-0 left-0 z-10 transition-all duration-1000 ease-in-out',
    heading:
      'text-2xl text-mf-off-black-950 font-bold mb-5 lg:text-4xl lg:font-semibold lg:mb-8',
    paragraph: 'text-xl text-mf-off-black-950 mb-10',
    linkWrapper: 'cursor-pointer flex items-center',
    linkButton: 'rounded-full text-white bg-mf-blue-base p-4 mr-5',
    linkText: 'text-mf-blue-base text-2xl font-semibold',
  },
};
