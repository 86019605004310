export const Section3 = () => (
  <div className={styles.container}>
    <div className={styles.innerWrapper}>
      <div className={styles.circleContainer.base}>
        <img
          className={styles.circleContainer.blueCircle}
          src="/images/home/section-3-img-2.png"
          alt="Mindfeed"
        />
      </div>
      <div className={styles.imageContainer.base}>
        <img
          className={styles.imageContainer.image}
          src="/images/home/section-3-img-1.png"
          alt="Mindfeed"
        />
      </div>
      <div className={styles.textContainer.base}>
        <h2 className={styles.textContainer.heading}>
          Why{' '}
          <span className={styles.textContainer.highlight}>use MindFeed</span>{' '}
          to organize your notes?
        </h2>
        <p className={styles.textContainer.paragraph}>
          Organizing and sharing your thoughts and ideas can be challenging.
          With MindFeed, you have a simple tool to capture, organize, and access
          your notes, projects, and inspirations, anytime and on any device.
        </p>
        <p className={styles.textContainer.boldParagraph}>
          {`Whether you're a student, entrepreneur, or creative, MindFeed helps you find and share your ideas quickly without the hassle.`}
        </p>
      </div>
    </div>
  </div>
);

const styles = {
  container: 'bg-mf-gray-50',
  innerWrapper:
    'flex flex-col-reverse px-5 mx-auto relative lg:flex-row lg:gap-20 lg:items-center lg:mt-6 lg:max-w-7xl',
  circleContainer: {
    base: 'absolute left-[-200px] bottom-0 overflow-hidden',
    blueCircle: 'min-w-[400px] min-h-[400px]',
  },
  imageContainer: {
    base: 'w-full h-[452px] lg:h-[705px] relative overflow-visible',
    image: 'absolute top-12 h-full',
  },
  textContainer: {
    base: 'max-w-[566px] my-10 lg:mt-[139px] lg:mb-[155px]',
    heading:
      'text-4xl font-bold text-mf-off-black-950 sm:leading-[57.28px] sm:text-5xl',
    highlight: 'text-mf-blue-base',
    paragraph: 'mt-8 text-xl text-mf-off-black-800 leading-8',
    boldParagraph: 'mt-8 text-xl font-bold text-mf-off-black-800 leading-8',
  },
};
